<template>
  <v-container>
    <v-row>
      <v-col class="col-sm-2 d-none d-sm-flex"></v-col>
      <v-col class="col-12 col-sm-8 pa-5">
        <h1>Honor Tag Cookie Policy</h1>
        <h2>Summary</h2>
        <p>Our Privacy Policy explains how we collect and use information from and about you when you use our products and services. This policy further explains how we use cookies and your choices over such use of cookies.</p>
        <h2>What is a cookie?</h2>
        <p>A browser cookie is a small piece of data that is stored on your device to help websites remember things about you. Other related technologies, such as local storage cookies, Flash cookies, and other identifiers associated with your device, may be used by websites for similar purposes. In this policy, we use “cookies” to refer to all of these technologies.</p>
        <h2>What types of cookies do we use?</h2>
        <p>We use session cookies, which exist only in temporary memory while the user navigates the website. We also use persistent cookies, which expire at a specific date or time instead of when the web browser is closed. In addition, we use third-party cookies. Normally, a cookie is associated with the host domain, and this is called a first-party cookie. A third-party cookie is from any other domain. This sort of cookie typically appears when webpages feature or utilize content from an external website.</p>
        <h2>How do we use cookies?</h2>
        <p>We use cookies for a number of reasons, such as protecting your account and improving your overall user experience. The cookies we use generally fall into one of the following categories:</p>
        <h3>Preferences</h3>
        <p>We use these cookies to remember your settings and preferences. For example, we may use these cookies to keep track of the user's input when filling online forms or shopping carts, or to keep track of the user’s preferences for language or font, for the duration of a session or slightly longer.</p>
        <h3>Security</h3>
        <p>We use these cookies to help identify and prevent security risks. For example, we may use these cookies to identify the user once the user has logged in, for the duration of a session, or to detect authentication abuses, for a limited persistent duration.</p>
        <h3>Process</h3>
        <p>We use these cookies to help deliver services that our users expect. For example, we may use these cookies to perform load balancing among our servers for the duration of a session.</p>
        <h3>Interoperability</h3>
        <p>We use these cookies to enable third-party plug-in and content sharing. For example, we may use these cookies to enable access by logged-in members of a third-party social network.</p>
        <h2>How do you control your cookies?</h2>
        <p>Your browser may allow you to block or otherwise limit the use of cookies. For example, your browser may provide you with the option to refuse some or all browser cookies. You may also be able to remove cookies from your browser. For more information about how to manage browser cookies, please follow the instructions provided by your browser. Keep in mind, however, that removing, rejecting, or limiting the use of cookies could affect the availability and functionality of our offerings.</p>
      </v-col>
      <v-col class="col-sm-2 d-none d-sm-flex"></v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Cookies'
  }
</script>

<style scoped>

</style>